/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Mobile nav
        $('i#nav-trigger').click(function(e) {
          $(this).next().slideToggle('fast');
        });

        // Checkboxes
        //$('form input[type="checkbox"]').checkboxX({threeState: false, size:'lg', enclosedLabel: true, iconChecked: '<i class="glyphicon glyphicon-ok"></i>'});

        // Tel overlay
        $('#tel-overlay i.glyphicon-remove').click(function(e) {
          $('#tel-overlay').fadeOut('slow');
        });
        $('a#cta-tel').click(function(e) {
          e.preventDefault();
          $('#tel-overlay').fadeIn('slow');
        });

        // Email overlay
        $('#share-overlay i.glyphicon-remove').click(function(e) {
          $('#share-overlay').fadeOut('slow');
        });

        $('a#cta-share').click(function(e) {
          e.preventDefault();
          $('#share-overlay').fadeIn('slow');
        });

        // Terms overlay
        $('i.glyphicon-remove').click(function(e) {
          $('div#terms-overlay').fadeOut('slow');
        });

        $('a#tc').click(function(e) {
          e.preventDefault();
          $('div#terms-overlay').fadeIn('slow');
        });

        //Hidden Terms
        $('.hidden-terms').click(function(){
            var id = $(this).attr("rel");

            $('#'+id).slideToggle('slow');                      
        });


        // Application form
        function application_ajax(e) {
          var that = $(e.target);
          e.preventDefault();

          // Client side checkbox validation
          if ($('#form-check').val() === '1') {

            $(that).find('.submit').prop('disabled', true);

            var datas = {
              action: 'form-callback',
              data: $(that).serialize()
            };

            $.post('/ajax', datas, function (response) {
              try {
                // Reset validation errors
                $(that).find('input').each(function () {
                  $(this).removeClass('error');
                });

                if (response.errors.length === 0) {
                  if (response.success === true) {

                    $(that).slideUp('slow', function () {
//                    console.log(response);
                    });
                  }
                } else {
                  $.each(response.errors, function (key, val) {
                    $(that).find('#' + val).addClass('error');
                    $(that).find('.submit').prop('disabled', false);
                  });
                }
                alert(response.message);
              }
              catch (err) {
                alert('Ett oväntat fel har inträffat! Försök igen senare.');
              }
            }, "json");
          } else {
            alert('Du måste godkänna villkoren för att kunna fortsätta.');
          }
        }

        // CTA: Beställ katalog
        $('form#cta-application').bind('submit', application_ajax);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
